import { Component, EventEmitter, Output } from '@angular/core';
import { NotificationService } from './../../../core/service/notification.service';

@Component({
    selector: 'message',
    template: `
        <div *ngIf="hasMessage()" role="alert"
                    [ngClass]="{'alert':(true),
                    'alert-info':(severity === 'info'),
                    'alert-warning':(severity === 'warning'),
                    'alert-danger':(severity === 'error'),
                    'alert-success':(severity === 'success')}">
            <button type="button" class="close" (click)="clear($event)" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            {{value}}
        </div>
     `,
    standalone: false
})

export class MessageComponent {

  value: string | undefined;

  severity: string| undefined;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(notification: NotificationService) {
    notification.message.subscribe(
      message => {
        this.value = message.value;
        this.severity = message.severity;
      }
    );
  }


    hasMessage() {
       return this.value && this.value.length > 0;
    }


   clear(event) {
      this.value = '';
      this.valueChange.emit(this.value);
      event.preventDefault();
   }

}
